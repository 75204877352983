import { TextField } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import formStyles from "../EqxForm/EqxForm.module.scss";
import styles from "./EqxTextArea.module.scss";

const InputTextArea = React.forwardRef((props, ref) => {
  return (
    <textarea
      {...props}
      className={classNames(formStyles.formField, styles.input)}
      ref={ref}
    >
      {props.value}
    </textarea>
  );
});

InputTextArea.propTypes = {
  value: PropTypes.string,
};

const EqxTextArea = ({
  className,
  fieldName,
  label,
  maxLength,
  placeholder,
  rows,
}) => {
  const { control } = useFormContext();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <Controller
      control={control}
      defaultValue=""
      name={fieldName ? fieldName : label}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <TextField
            aria-invalid={!!error}
            className={classNames(
              formStyles.formField,
              styles.input,
              className
            )}
            data-is="EqxTextArea"
            error={!!error}
            fullWidth={true}
            inputProps={{ maxLength: 150 }}
            label={label}
            multiline={true}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows ? rows : 4}
            type={"text"}
            value={value}
            variant="outlined"
          />
          {maxLength && (
            <p className={styles.charactersCount}>
              {!value || value.length === 0
                ? maxLength
                : maxLength - value.length}{" "}
              characters
            </p>
          )}
        </>
      )}
      rules={{ required: true }}
    />
  );
};

EqxTextArea.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

InputTextArea.displayName = "InputTextArea";

export default EqxTextArea;
